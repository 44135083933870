import * as actionTypes from 'actions';

const initialState = {
  list: [],
  loading: true,
  page: 0,
  pageSize: 10,
  query: '',
  keyword: { list: [], page: 0, pageSize: 10, query: '', loading: false }
};

export default function testReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.TEST_LOADING: {
      return {
        ...state,
        loading: Boolean(payload)
      };
    }
    case actionTypes.TEST_PAGE: {
      return {
        ...state,
        page: payload
      };
    }
    case actionTypes.TEST_FETCH_LIST: {
      return {
        ...state,
        ...payload,
        list:
          payload.page === 0 ? payload.list : [...state.list, ...payload.list]
      };
    }
    case actionTypes.TEST_KEYWORD_LOADING: {
      return {
        ...state,
        keyword: {
          ...state.keyword,
          ...payload,
          list: [
            payload.page === 0
              ? payload.list
              : [...state.keywork.list, ...payload.list]
          ]
        }
      };
    }
    default: {
      return state;
    }
  }
}
