import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import uuid from 'uuid/v4';
import useRouter from 'utils/useRouter';
import { Link } from 'react-router-dom';

const QuestionLibraryNav = () => {
  const router = useRouter();
  return (
    <Grid container justify="flex-end" style={{ position: 'sticky', top: 64 }}>
      <List>
        {['Tests', 'Passages', 'Questions'].map((name = '') => (
          <ListItem key={uuid()}>
            <Link to={`/question-library/${name.toLocaleLowerCase()}`}>
              <ListItemText>
                <Typography
                  variant="h5"
                  color={
                    router.location.pathname.match(
                      `/question-library/${name.toLocaleLowerCase()}`
                    )
                      ? 'primary'
                      : 'initial'
                  }>
                  {name}
                </Typography>
              </ListItemText>
            </Link>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default QuestionLibraryNav;
