import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Grid,
  Typography
} from '@material-ui/core';
import SkeletonPlaceholder from 'components/SkeletonPlaceholder';
import { Link } from 'react-router-dom';
import { DEFAULT_PAGESIZE } from 'constant';

export default function Tests({
  data = [],
  isLoading,
  page,
  onFetchMoreData = () => null,
  isUseFetchMore,
  total
}) {
  const [isCallFetchMore, setIsCallFetmore] = useState(false);

  useEffect(() => {
    if (isCallFetchMore) {
      onFetchMoreData();
      setIsCallFetmore(false);
    }
  }, [isCallFetchMore, onFetchMoreData]);
  useEffect(() => {
    if (!isUseFetchMore) return;
    const onScroll = e => {
      if (!document.getElementById('scroll-to') || isCallFetchMore) return;
      const hT =
        (window.pageYOffset + window.innerHeight) /
        document.getElementById('get-height').offsetHeight;

      if (hT > 0.9) {
        setIsCallFetmore(true);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [isCallFetchMore, isUseFetchMore]);
  return (
    <React.Fragment>
      {isLoading && page === 0 && <SkeletonPlaceholder noItems={10} />}
      {isUseFetchMore ? (
        <div id="get-height">
          {data.map(test => (
            <Link key={test.id} to={`/question-library/tests/${test.id}`}>
              <Test {...test} />
            </Link>
          ))}
        </div>
      ) : (
        data.map(test => (
          <Link key={test.id} to={`/question-library/tests/${test.id}`}>
            <Test {...test} />
          </Link>
        ))
      )}

      {isLoading && page > 0 && <SkeletonPlaceholder noItems={10} />}
      {(page + 1) * DEFAULT_PAGESIZE < total && isUseFetchMore && !isLoading && (
        <Grid
          id="scroll-to"
          onClick={onFetchMoreData}
          container
          justify="center">
          <Typography color={'primary'} variant="body1">
            LOAD MORE
          </Typography>
        </Grid>
      )}
    </React.Fragment>
  );
}

function Test({ title, id, sessions, description }) {
  const titleId = `passage-title-${id}`;
  const descriptionId = `passage-description-${id}`;
  useEffect(() => {
    document.getElementById(titleId).innerHTML = title;
    document.getElementById(descriptionId).innerHTML = description;
  }, [descriptionId, description, title, titleId]);
  return (
    <Card style={{ marginBottom: 16 }}>
      <CardHeader
        title={<div id={titleId}></div>}
        subheader={<div id={descriptionId}></div>}></CardHeader>
      <CardActions>
        <Button>
          {sessions} {sessions > 1 ? 'sessions' : 'session'}
        </Button>
      </CardActions>
    </Card>
  );
}
