import { combineReducers } from 'redux';

import passage from './passage';
import question from './question';
import session from './sessionReducer';
import test from './test';

const rootReducer = combineReducers({
  passage,
  question,
  session,
  test
});

export default rootReducer;
