import React, { Suspense, useEffect } from 'react';
import { Avatar, Button, LinearProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { fade, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import { NavLink as RouterLink } from 'react-router-dom';
import useDebounce from 'utils/useDebounce';
import useRouter from 'utils/useRouter';
import { login, search, updateReponsive } from '../../actions/sessionActions';

const useStyles = makeStyles(theme => ({
  content: { marginTop: 56 },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export default function PrimarySearchAppBar(props) {
  const { route } = props;

  const classes = useStyles();
  const router = useRouter();

  const session = useSelector(state => state.session);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, UPDATE_RESPONSIVEMoreAnchorEl] = React.useState(
    null
  );
  const debounceSize = useDebounce(size => {
    dispatch(updateReponsive(size));
  }, 400);
  React.useLayoutEffect(() => {
    dispatch(updateReponsive([window.innerWidth, window.innerHeight]));
  }, [dispatch]);

  React.useLayoutEffect(() => {
    function updateSize() {
      debounceSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [debounceSize]);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const jwt = localStorage.getItem('jwt');

  useEffect(() => {
    const arr =
      router.location.search && router.location.search.split('?token=');
    if (arr.length > 0) {
      localStorage.setItem('jwt', 'Bareer ' + arr[1]);
      dispatch(login());
      router.history.replace('/');
    }
  }, [
    router.match.params.token,
    router.history,
    dispatch,
    router.location.search
  ]);
  useEffect(() => {
    jwt && dispatch(login());
  }, [dispatch, jwt]);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    UPDATE_RESPONSIVEMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleMobileMenuOpen = event => {
  //   UPDATE_RESPONSIVEMoreAnchorEl(event.currentTarget);
  // };
  let query = '';
  const debounceQuery = useDebounce(query => {
    dispatch(search(query));
  }, 400);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <MenuItem
        onClick={() => {
          handleMenuClose();
          localStorage.removeItem('jwt');
          window.location.reload();
        }}>
        Signout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );
  function validateMacthRoute(route = '/') {
    const params = router.location.pathname.split('/');
    return params[1] === route;
  }
  return (
    <React.Fragment>
      <AppBar position="fixed" color="inherit" style={{ height: 56 }}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={clsx(classes.menuButton, classes.sectionMobile)}
            color="inherit"
            aria-label="open drawer">
            <MenuIcon />
          </IconButton> */}
          <RouterLink to="/" style={{ display: 'flex', marginRight: 16 }}>
            <img
              style={{ height: '42px' }}
              alt="Logo"
              src="/images/logos/datlp_logo.png"
            />
          </RouterLink>

          <div className={clsx(classes.search)}>
            <div className={classes.searchIcon}></div>

            <Grid container spacing={1} alignItems="flex-end">
              <Grid item></Grid>
              <Grid item>
                <TextField
                  onChange={({ target: { value } }) => {
                    query = value;
                    debounceQuery(query);
                  }}
                  className={classes.margin}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search..."
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes.grow} />
          <Button
            className={classes.sectionDesktop}
            color={
              validateMacthRoute('question-library') ? 'primary' : 'inherit'
            }
            onClick={() => {
              router.history.push('/question-library');
            }}>
            Question library
          </Button>
          <Button
            className={classes.sectionDesktop}
            color={validateMacthRoute('tools') ? 'primary' : 'inherit'}
            onClick={() => {
              router.history.push('/tools');
            }}>
            Tools
          </Button>
          {session.id ? (
            <Avatar alt={session.id} src={session.picture} />
          ) : (
            <AccountCircleOutlinedIcon
              color="primary"
              onClick={() => {
                window.location.replace(
                  'https://question-library.herokuapp.com/google'
                );
              }}
            />
          )}
        </Toolbar>
      </AppBar>
      {/* <NavBar open></NavBar> */}
      {renderMobileMenu}
      {renderMenu}
      <main className={classes.content}>
        <Suspense fallback={<LinearProgress />}>
          {renderRoutes(route.routes)}
        </Suspense>
      </main>
    </React.Fragment>
  );
}
