/** @format */

import requests from 'services/base';

const ROUTE = ``;

export const authenticationApis = {
  signup: data => requests.post(`${ROUTE}/signup`, data),
  login: data => requests.get(`google`, data),
  me: data => requests.get(`me`, data || {}),
  sample: data => requests.get(`${ROUTE}/sample`, data),
  getExisted: data => requests.post(`${ROUTE}/existed`, data),
  create: data => requests.post(`${ROUTE}`, data),
  update: ({ id, data }) => requests.put(`${ROUTE}/${id}`, data),
  delete: ({ id }) => requests.delete(`${ROUTE}/${id}`),
  reset: data => requests.post(`${ROUTE}/reset-password`, data)
};
