import React, { useEffect, useState } from 'react';
import { Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { keywordApi } from 'services';
import uuid from 'uuid/v4';

const Keywords = ({ data = [], isQuestion, isPassage, isTest }) => {
  const isRenderDataOutside = data.length > 0;
  const [datalist, setDatalist] = useState(data);
  const [seleted, setSeleted] = useState([]);
  useEffect(() => {
    if (isRenderDataOutside) return;
    async function fetch(params) {
      const result = await keywordApi.get({ isQuestion, isPassage, isTest });
      Array.isArray(result.list) && setDatalist(result.list);
    }

    fetch();
  }, [isRenderDataOutside, isQuestion, isPassage, isTest]);
  return (
    <Grid style={{ position: 'sticky', top: '64px' }}>
      {seleted.length > 0 && (
        <List>
          {seleted.map(({ title }) => (
            <ListItem key={uuid()}>
              <ListItemText># {title}</ListItemText>
            </ListItem>
          ))}
        </List>
      )}

      <List>
        {datalist.map(({ title }) => (
          <ListItem key={uuid()}>
            <ListItemText># {title}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Grid>
  );
};

export default Keywords;
