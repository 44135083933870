export const TOOL_TEXTS_ROUTE = '/tools/lines-';
export const DEFAULT_PAGESIZE = 10;
export const DEFAULT_PAGESIZE_PASSAGE = 10;
export const TOOL_TEXTS_CONST = {
  ADD_TEXT_EACH_LINES: { label: 'Add text each lines' },
  CHANGE_ALIAS: { label: 'Change alias' },
  CLONE_EACH_LINES: { label: 'Clone each lines' },
  REGEX: { label: 'Regex' },
  REMOVE_DUBLICATE_LINES: { label: 'Remove dublicate lines' },
  GROUP_LINES: { label: 'Group lines' },
  TIME_STRING_TO_SECOND: { label: 'Time string to second' }
};
