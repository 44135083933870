import * as actionTypes from 'actions';

const initialState = {
  user: null,
  keywordIds: []
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        ...action.payload
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST'
        }
      };
    }
    case actionTypes.UPDATE_RESPONSIVE: {
      try {
        if (action.payload[0] > 1280)
          return {
            ...state,
            device: 'desktops'
          };
        if (action.payload[0] > 1025)
          return {
            ...state,
            device: 'laptops'
          };
        if (action.payload[0] > 786)
          return {
            ...state,
            device: 'tablets'
          };
      } catch (error) {}
      return {
        ...state,
        device: 'mobiles'
      };
    }

    case actionTypes.KEYWORDS_FILTER: {
      return {
        ...state,
        keywordIds: action.payload
      };
    }
    case actionTypes.SEARCH: {
      return {
        ...state,
        query: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default sessionReducer;
