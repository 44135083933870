import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  Typography
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import { SkeletonPlaceholder } from 'components';
import uuid from 'uuid/v4';
import { DEFAULT_PAGESIZE } from 'constant';

const useStyles = makeStyles(theme => ({}));
export default function Questions({
  data = [],
  isLoading,
  page,
  onFetchMoreData = () => null,
  isUseFetchMore,
  index,
  total
}) {
  const [isCallFetchMore, setIsCallFetmore] = useState(false);
  useEffect(() => {
    if (isUseFetchMore) {
      const onScroll = e => {
        if (!document.getElementById('scroll-to') || isCallFetchMore) return;
        const hT =
          (window.pageYOffset + window.innerHeight) /
          document.getElementById('get-height').offsetHeight;

        if (hT > 0.9) {
          setIsCallFetmore(true);
        }
      };
      window.addEventListener('scroll', onScroll);

      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [isCallFetchMore, isUseFetchMore]);
  useEffect(() => {
    if (isCallFetchMore) {
      onFetchMoreData();
      setIsCallFetmore(false);
    }
  }, [isCallFetchMore, onFetchMoreData]);
  return (
    <List>
      {isLoading && page === 0 && (
        <SkeletonPlaceholder key={uuid()} noItems={10} />
      )}
      {isUseFetchMore ? (
        <div id="get-height">
          {data.map((question, qIndex) => (
            <Question
              key={uuid()}
              {...question}
              index={index ? `${index}.` : qIndex + 1}
            />
          ))}
        </div>
      ) : (
        data.map((question, qIndex) => (
          <Question
            key={uuid()}
            {...question}
            index={index ? `${index}.` : qIndex + 1}
          />
        ))
      )}

      {isLoading && page > 0 && (
        <SkeletonPlaceholder key={uuid()} noItems={10} />
      )}
      {(page + 1) * DEFAULT_PAGESIZE < total && isUseFetchMore && !isLoading && (
        <Grid
          id="scroll-to"
          onClick={onFetchMoreData}
          container
          justify="center">
          <Typography color={'primary'} variant="body1">
            LOAD MORE
          </Typography>
        </Grid>
      )}
    </List>
  );
}

function Question({
  id,
  title,
  answers = [],
  description,
  explanation,
  index,
  isMultiple
}) {
  title = index ? `${index} · ${title}` : title;
  const formHelperTextId = `helperText|description${id}`;
  const classes = useStyles();
  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [isShowExplan, setIsShowExplan] = useState(false);
  const [isShowOpenResp, setIsShowOpenResp] = useState(false);
  const rightIds = answers
    .filter(({ isRight }) => Boolean(isRight))
    .map(({ id }) => id);
  const [helperText, setHelperText] = React.useState(description || '');

  useEffect(() => {
    if (helperText) return;
    document.getElementById(formHelperTextId).innerHTML = description;
  }, [helperText, description, formHelperTextId]);

  const handleRadioChange = event => {
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!event.target.value) {
      setHelperText('Please select an option.');
      setError(true);
      return;
    }

    if (rightIds.includes(event.target.value)) {
      setHelperText('You got it!');
      setError(false);
    } else {
      setHelperText('Sorry, wrong answer!');
      setError(true);
    }
  };

  return (
    <React.Fragment>
      <Card style={{ marginBottom: 16 }}>
        <CardHeader
          title={
            <div dangerouslySetInnerHTML={{ __html: title }}></div>
          }></CardHeader>
        <CardContent>
          <FormControl
            component="fieldset"
            error={error}
            className={classes.formControl}>
            <FormHelperText id={formHelperTextId}>
              {helperText || description || ''}
            </FormHelperText>
          </FormControl>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
