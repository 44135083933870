/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
import ErrorLayout from './layouts/Error';
import Home from './views/Home';
import TestDetail from './views/TestDetail';

const routes = [
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home
      },
      {
        path: '/question-library',
        exact: true,
        component: () => <Redirect to="/question-library/tests" />
      },
      {
        path: '/question-library/questions',
        exact: true,
        component: lazy(() => import('views/Questions'))
      },
      {
        path: '/question-library/passages',
        exact: true,
        component: lazy(() => import('views/Passages'))
      },
      {
        path: '/question-library/tests',
        exact: true,
        component: lazy(() => import('views/Tests'))
      },
      {
        path: '/question-library/tests/:id',
        exact: true,
        component: TestDetail
      },

      {
        path: '/tools',
        exact: true,
        component: lazy(() => import('views/Tools'))
      },
      {
        path: '/tools/:tool',
        exact: true,
        component: lazy(() => import('views/Tools'))
      },
      {
        component: () => <Redirect to="/" />
      }
    ]
  }
];

export default routes;
