import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { ListItem, ListItemText } from '@material-ui/core';
import uuid from 'uuid/v4';

const SkeletonPlaceholder = ({ noItems }) => {
  return Array(noItems || 1)
    .fill(0)
    .map(() => (
      <React.Fragment key={uuid()}>
        <ListItem>
          <ListItemText>
            <Skeleton variant="text"></Skeleton>
          </ListItemText>
        </ListItem>
        <ListItem>
          <Skeleton
            style={{ width: '100%', height: 'calc(100vh/4)' }}
            variant="rect"></Skeleton>
        </ListItem>
      </React.Fragment>
    ));
};

export default SkeletonPlaceholder;
