import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import useRouter from 'utils/useRouter';
import uuid from 'uuid/v4';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {},
  title: { padding: theme.spacing(1) },
  divider: { marginBottom: theme.spacing(1) }
}));
const TOOLS = [
  {
    title: 'LINES',
    subs: [
      'Add text each lines',
      'Change alias',
      'Clone each lines',
      'Regex',
      'Remove dublicate lines',
      'Group lines',
      'Time string to second'
    ]
  },
  // {
  //   title: 'MATHEMATICS',
  //   subs: [
  //     'Calculator',
  //     'Area calculator',
  //     'Percentage calculator',
  //     'Single rule of three direct',
  //     'Single rule of three inverse'
  //   ]
  // },
  // {
  //   title: 'COLORS',
  //   subs: [
  //     'Lighten color',
  //     'Darken color',
  //     'Change color saturation',
  //     'Greyscale/desaturate a color',
  //     'Invert a color'
  //   ]
  // },
  // {
  //   title: 'TEXT AND LISTS',
  //   subs: [
  //     'Reverse list',
  //     'List randomizer',
  //     'Sort list',
  //     'Add text to each line',
  //     'Remove extra whitespaces'
  //   ]
  // },
  // {
  //   title: 'NUMBERS',
  //   subs: [
  //     'Generate list of numbers',
  //     'Filter numbers',
  //     'Sort numbers',
  //     'Minimum and maximum of a list',
  //     'Average of a list'
  //   ]
  // },
  // {
  //   title: 'DATE AND TIME',
  //   subs: [
  //     'Date/time difference',
  //     'Add to a date',
  //     'Subtract from a date',
  //     'Timer',
  //     'Stopwatch',
  //     ''
  //   ]
  // },
  // {
  //   title: 'IMAGES',
  //   subs: [
  //     'Invert colors',
  //     'Flip image',
  //     'Darken image',
  //     'Lighten image',
  //     'Change brightness'
  //   ]
  // },
  // {
  //   title: 'RANDOMNESS',
  //   subs: [
  //     'Random number generator',
  //     'Coin flipper',
  //     'Dice roller',
  //     'Gaussian random number generator',
  //     'Password generator'
  //   ]
  // },
  // {
  //   title: 'FILES',
  //   subs: [
  //     'Split files',
  //     'Join files',
  //     'Base64 encode',
  //     'Base64 decode',
  //     'Random file generator'
  //   ]
  // },
  // {
  //   title: 'PROGRAMMING',
  //   subs: [
  //     'Syntax highlighter',
  //     'CSS Inliner',
  //     'JSON formatter',
  //     'CSS beautifier',
  //     'HTML beautifier'
  //   ]
  // }
];
const PROJECTS = [
  {
    title: 'QUESTION LIBRARY',
    subs: ['Tests', 'Passages', 'Questions']
  }, {
    title: 'NOTATION',
    subs: ['Newest',]
  }
];

const Homepage = () => {
  const { device } = useSelector(state => state.session);
  const classes = useStyles();
  const router = useRouter();

  function renderProjects(params) {
    return PROJECTS.map(tool => (
      <Grid item sm={12} md={3} key={uuid()}>
        <Card>
          <CardHeader title={tool.title}></CardHeader>
          <Divider></Divider>
          <CardContent>
            <List>
              {tool.subs.map(sub => (
                <ListItem
                  style={{ cursor: 'pointer' }}
                  key={uuid()}
                  onClick={() => {
                    router.history.push(
                      `/${`${tool.title}/${sub}`
                        .toLowerCase()
                        .replace(/[ ]{1,}/, '-')}`
                    );
                  }}>
                  <ListItemText>{sub}</ListItemText>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    ));
  }

  function renderTools(params) {
    return TOOLS.map(tool => (
      <Grid item md={3} sm={12} key={uuid()}>
        <Card style={{ margin: 8 }}>
          <CardHeader title={tool.title}></CardHeader>
          <Divider></Divider>
          <CardContent>
            <List>
              {tool.subs.map(sub => (
                <ListItem
                  style={{ cursor: 'pointer' }}
                  key={uuid()}
                  onClick={() => {
                    router.history.push(
                      `/tools/${`${tool.title}-${sub}`
                        .toLowerCase()
                        .replace(/[ ]{1,}/gi, '-')}`
                    );
                  }}>
                  <ListItemText>{sub}</ListItemText>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Grid>
    ));
  }

  return (
    <Page className={classes.root} title="Homepage">
      <Grid container>
        <Typography className={classes.title} variant="body1">
          PROJECTS
        </Typography>
      </Grid>
      <Divider className={classes.divider}></Divider>
      {['mobiles', 'tablets'].includes(device) ? (
        renderProjects()
      ) : (
          <Grid container justify="center">
            {renderProjects()}
          </Grid>
        )}

      <Grid container>
        <Typography className={classes.title} variant="body1">
          TOOLS
        </Typography>
      </Grid>

      <Divider className={classes.divider}></Divider>
      {['mobiles', 'tablets'].includes(device) ? (
        renderTools()
      ) : (
          <Grid container justify="center">
            {renderTools()}
          </Grid>
        )}
    </Page>
  );
};

export default Homepage;
