import { keywordApi, testApi } from 'services';

export const TEST_LOADING = 'TEST_LOADING';
export const TEST_PAGE = 'TEST_PAGE';
export const TEST_KEYWORD_LOADING = 'TEST_KEYWORD_LOADING';
export const TEST_FETCH_LIST = 'TEST_FETCH_LIST';
export const TEST_KEYWORD_FETCH_LIST = 'TEST_KEYWORD_FETCH_LIST';

export const testLoading = isLoading => dispatch => {
  return dispatch({
    type: TEST_LOADING,
    payload: isLoading
  });
};
export const testPage = page => dispatch => {
  return dispatch({
    type: TEST_PAGE,
    payload: page
  });
};

export const testFetchList = ({ page, query, ...rest }) => async dispatch => {
  const result = await testApi.get({ page, query, ...rest });
  dispatch({
    type: TEST_LOADING,
    payload: false
  });
  return dispatch({
    type: TEST_FETCH_LIST,
    payload: result
  });
};

export const testKeywordFetchList = ({
  page,
  query,
  ...rest
}) => async dispatch => {
  return dispatch({
    type: TEST_KEYWORD_FETCH_LIST,
    payload: await keywordApi.get({ page, query, isTest: true, ...rest })
  });
};

export const testKeywordLoading = isLoading => dispatch => {
  return dispatch({
    type: TEST_KEYWORD_LOADING,
    payload: isLoading
  });
};
