import React, { useState, useEffect } from 'react';
import { List, Grid, Typography, Card, CardHeader } from '@material-ui/core';
import { Questions, SkeletonPlaceholder } from 'components';
import './style.scss';
import ReactAudioPlayer from 'react-audio-player';
import uuid from 'uuid/v4';
import { makeStyles } from '@material-ui/styles';
import { DEFAULT_PAGESIZE_PASSAGE } from 'constant';
const useStyles = makeStyles(theme => ({
  root: {},
  inner: {}
}));
export default function Passages({
  data = [],
  isLoading,
  page,
  onFetchMoreData = () => null,
  isUseFetchMore,
  id,
  total
}) {
  const [isCallFetchMore, setIsCallFetmore] = useState(false);

  useEffect(() => {
    const onScroll = e => {
      if (!document.getElementById('scroll-to')) return;
      const hT =
        (window.pageYOffset + window.innerHeight) /
        document.getElementById('get-height').offsetHeight;

      if (hT > 0.9) {
        setIsCallFetmore(true);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  useEffect(() => {
    if (isCallFetchMore) {
      onFetchMoreData();
      setIsCallFetmore(false);
    }
  }, [isCallFetchMore, onFetchMoreData]);
  return (
    <List>
      {isLoading && page === 0 && (
        <SkeletonPlaceholder key={uuid()} noItems={10} />
      )}
      {isUseFetchMore ? (
        <div id="get-height">
          {data.map((passage, index) => (
            <Passage key={uuid()} {...passage} index={index + 1} />
          ))}
        </div>
      ) : (
        data.map((passage, index) => (
          <Passage key={uuid()} {...passage} index={index + 1} />
        ))
      )}
      {isLoading && page > 0 && (
        <SkeletonPlaceholder key={uuid()} noItems={10} />
      )}
      {(page + 1) * DEFAULT_PAGESIZE_PASSAGE < total &&
        isUseFetchMore &&
        !isLoading && (
          <Grid
            id="scroll-to"
            onClick={onFetchMoreData}
            container
            justify="center">
            <Typography color={'primary'} variant="body1">
              LOAD MORE
            </Typography>
          </Grid>
        )}
    </List>
  );
}
function Passage({
  title,
  questions = [],
  description = '',
  id,
  className,
  audioUrl,
  index,
  ...rest
}) {
  const classes = useStyles();
  const titleId = `passage-title-${id}`;
  const descriptionId = `passage-description-${id}`;
  useEffect(() => {
    const arr = title.split(/[_]{2,}/g);
    document.getElementById(titleId).innerHTML = arr
      .map((item, indexx) =>
        indexx === arr.length - 1
          ? item
          : `${item}__${index ? `${index}.${indexx + 1}` : `${indexx + 1}`}__`
      )
      .join('');
    document.getElementById(descriptionId).innerHTML = description;
  }, [descriptionId, description, title, titleId, index]);

  return (
    <div className={classes.inner}>
      <Grid className="element-sticky-container">
        <Grid className="passages-element-sticky">
          {audioUrl && (
            <Grid container justify="flex">
              <ReactAudioPlayer src={audioUrl} controls />
            </Grid>
          )}
          <Card>
            <CardHeader
              subheader={<div id={descriptionId}></div>}
              title={<div id={titleId}></div>}
            />
          </Card>
        </Grid>
        <Grid>
          <div className={classes.content}>
            <List disablePadding>
              <Questions data={questions} index={index} />
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
