/** @format */

import superagent from 'superagent';

export const SERVER = 'https://question-library.herokuapp.com';
const generateServer = name => {
  switch (name) {
    case 'media':
      return 'https://cdn-image-engdb.herokuapp.com';

    default:
      return 'https://question-library.herokuapp.com';
  }
};
export const genParamsString = ({ serverName, ...rest }) => {
  const data = { ...rest };
  if (!data || typeof data === 'string') return '';

  let str = '?';
  Object.keys(data)
    .filter(item => data[item])
    .forEach(item => (str += `${item}=${data[item]}&`));

  return str.substr(0, str.length - 1);
};

export default {
  get: (url, data = {}) => {
    const { serverName, ...rest } = data;
    const req = superagent.get(
      `${generateServer(serverName)}/${url}${genParamsString({ ...rest })}`
    );

    req.set('Authorization', localStorage.getItem('jwt'));

    return req
      .then(function onSuccess(res) {
        const { statusCode } = res;
        return { ...res.body, statusCode };
      })
      .catch(({ response = {} }) => {
        const { statusCode, body } = response;
        return { error: { statusCode, ...body } };
      });
  },
  post: (url, data = {}) => {
    const { serverName, ...rest } = data;
    const token = localStorage.getItem('jwt');

    return token
      ? superagent
          .post(`${generateServer(serverName)}/${url}`, { ...rest })
          .set('Authorization', localStorage.getItem('jwt'))
          .then(function onSuccess(res) {
            const { statusCode } = res;
            return { ...res.body, statusCode };
          })
          .catch(({ response = {} }) => {
            const { statusCode, body } = response;
            return { error: { statusCode, ...body } };
          })
      : superagent
          .post(`${generateServer(serverName)}/${url}`, { ...rest })
          .then(
            function onSuccess(res) {
              const { statusCode } = res;
              return { ...res.body, statusCode };
            },
            function onError(err) {
              console.log('onError -> err', err.message);
            }
          );
  },
  put: (url, { serverName, ...rest }) => {
    return superagent
      .put(`${generateServer(serverName)}/${url}`, { ...rest })
      .set('Authorization', localStorage.getItem('jwt'))
      .then(function onSuccess(res) {
        const { statusCode } = res;
        return { ...res.body, statusCode };
      })
      .catch(({ response = {} }) => {
        const { statusCode, body } = response;
        return { error: { statusCode, ...body } };
      });
  },
  delete: (url, { serverName, ...rest }) => {
    return superagent
      .del(`${generateServer(serverName)}/${url}`, { ...rest })
      .set('Authorization', localStorage.getItem('jwt'))
      .then(function onSuccess(res) {
        const { statusCode } = res;
        return { ...res.body, statusCode };
      })
      .catch(({ response = {} }) => {
        const { statusCode, body } = response;
        return { error: { statusCode, ...body } };
      });
  }
};
