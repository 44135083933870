import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  Keywords,
  Page,
  Passages,
  QuestionLibraryNav,
  Questions,
  SkeletonPlaceholder
} from 'components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { testApi } from 'services';
import useRouter from 'utils/useRouter';
import uuid from 'uuid';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const TestDetail = props => {
  const classes = useStyles();
  const {
    match: {
      params: { id }
    }
  } = useRouter();

  const [data, setData] = useState({});
  const { device } = useSelector(state => state.session);
  const [isLoading, setIsLoading] = useState(true);
  const noSessions = (data.sessions && data.sessions.length) || 0;
  useEffect(() => {
    async function fetch(params) {
      setIsLoading(true);
      const result = await testApi.getDetail({ id });
      result && setData(result);
      setIsLoading(false);
    }
    fetch();
  }, [id]);

  function renderMain(params) {
    return (
      <React.Fragment>
        {isLoading && <SkeletonPlaceholder noItems={10} />}
        {!isLoading && (
          <Card style={{ marginTop: 16 }}>
            <CardHeader
              title={data.title}
              subheader={
                <React.Fragment>
                  <div>
                    {noSessions > 1
                      ? noSessions + ' sessions'
                      : noSessions + ' session'}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.description || ''
                    }}></div>
                </React.Fragment>
              }></CardHeader>
          </Card>
        )}
        {!isLoading &&
          data &&
          data.sessions &&
          data.sessions.map((session, index) => (
            <React.Fragment key={uuid()}>
              <Questions data={session.questions || []} index={index} />
              <Passages data={session.passages || []} index={index} />
            </React.Fragment>
          ))}
      </React.Fragment>
    );
  }

  return (
    <Page
      className={classes.root}
      title={(data && data.title) || 'Test detail'}>
      {['mobiles', 'tablets'].includes(device) ? (
        renderMain()
      ) : (
        <Grid container>
          <Grid item sm={12} md={3}>
            <QuestionLibraryNav />
          </Grid>
          <Grid item sm={12} md={6}>
            {renderMain()}
          </Grid>
          <Grid item sm={12} md={3}>
            <Keywords data={data.keywords} />
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

TestDetail.propTypes = {
  className: PropTypes.string
};

export default TestDetail;
